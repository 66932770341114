import React from "react"
import {
    Layout, SignIn  
} from '../app/components'

export default props => {
    return(
        <Layout location={props.location}>  
            <SignIn />                                 
        </Layout>     
    )
}